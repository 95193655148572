<template>
  <a-modal
    :width="525"
    title="新增开票信息"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="onSubmit"
    @cancel="handleCancel5"
  >
    <a-form-model
      ref="ruleForm"
      :model="form1"
      :rules="rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-model-item label="抬头类型">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3px;
          "
        >
          <a-radio-group v-model="form1.headerType" @change="clearForm">
            <a-radio value="2">企业</a-radio>
            <a-radio value="1">个人</a-radio>
          </a-radio-group>
          <a-popover>
            <template slot="content">
              <div style="max-width: 300px">
                <p style="font-weight: 500">
                  1.什么是企业税号？（请准确填写贵单位号码，以免影响您的发票报销）
                </p>
                <p>
                  企业税号即纳税人识别号/统一社会信用代码，每个企业的识别号都是唯一的，是一组长度为18位的用于法人和其他组织身份识别的代码，统一社会信用代码由国家标准委发布。2015年10月1日起，国家启动将企业依次申请的工商营业执照、组织机构代码证和税务登记证三证合为一证，并将三证号码合并为统一社会信用代码。
                </p>
                <p style="font-weight: 500">2.如何获取/知晓企业税号？</p>
                <p>
                  您可向贵单位的财务部门索取；也可以根据单位名称在
                  <a
                    href="http://www.gsxt.gov.cn/index.html"
                    target="_blank"
                    style="text-decoration: underline"
                  >
                    国家企业信用信息公示系统
                  </a>
                  查询统一社会信用代码。
                </p>
              </div>
            </template>
            <a-button icon="info-circle" type="link"> 发票须知 </a-button>
          </a-popover>
        </div>
      </a-form-model-item>
      <div v-if="form1.headerType == '2'">
        <a-form-model-item label="公司名称" prop="firmName">
          <div
            style="position: relative"
            v-click-outside="
              () => {
                placeCompanyVisible = false;
              }
            "
          >
            <a-input
              v-model.trim="form1.firmName"
              placeholder="请输入公司名称"
              :max-length="50"
              style="z-index: 200"
              @change="onChangeFirmName"
              @focus="placeCompanyVisible = true"
            />
            <a-card
              v-if="placeCompanyVisible && placeCompanyArray.length"
              class="pop-view"
              size="small"
            >
              <p
                v-for="(item, index) in placeCompanyArray"
                :key="index"
                style="cursor: pointer"
                @click="onSelectCompany(item)"
              >
                {{ item.unitName }}
              </p>
            </a-card>
          </div>
        </a-form-model-item>
        <a-form-model-item label="企业税号" prop="firmDutyNumber">
          <a-input
            v-model.trim="form1.firmDutyNumber"
            placeholder="请输入企业税号"
            :max-length="20"
          />
        </a-form-model-item>
        <a-form-model-item label="开户银行" prop="firmBank">
          <a-input
            v-model.trim="form1.firmBank"
            placeholder="请输入开户银行"
            :max-length="50"
          />
        </a-form-model-item>
        <a-form-model-item label="开户行账号" prop="firmBankSn">
          <a-input
            type="number"
            v-model.trim="form1.firmBankSn"
            placeholder="请输入开户行账号"
            :max-length="20"
          />
        </a-form-model-item>
        <!-- <a-form-model-item label="企业地址" prop="cityId">
            <address-box
              :form="addressForm"
              :isEdit="isEdit"
              ref="address"
              @addressId="selectAddress"
              @waitCity="waitCity"
            />
          </a-form-model-item> -->
        <a-form-model-item label="企业地址" prop="addressDetail">
          <a-input
            v-model.trim="form1.addressDetail"
            placeholder="请输入企业的详细地址"
            :max-length="80"
          />
        </a-form-model-item>
        <a-form-model-item label="企业电话" prop="firmPhone">
          <a-input
            v-model.trim="form1.firmPhone"
            placeholder="请输入企业电话"
            :max-length="20"
          />
        </a-form-model-item>
        <a-form-model-item label="收票邮箱" prop="firmEmail">
          <a-input
            v-model.trim="form1.firmEmail"
            placeholder="请输入收票邮箱"
            :max-length="50"
          />
        </a-form-model-item>
      </div>
      <div v-if="form1.headerType == '1'">
        <a-form-model-item label="姓名" prop="invoiceName">
          <a-input
            v-model.trim="form1.invoiceName"
            placeholder="请输入姓名"
            :max-length="20"
          />
        </a-form-model-item>
        <a-form-model-item label="电话" prop="invoicePhone">
          <a-input
            v-model.trim="form1.invoicePhone"
            placeholder="请输入电话"
            :max-length="20"
          />
        </a-form-model-item>
        <a-form-model-item label="邮箱" prop="invoiceEmail">
          <a-input
            v-model.trim="form1.invoiceEmail"
            placeholder="请输入邮箱"
            :max-length="50"
          />
        </a-form-model-item>
      </div>
      <a-form-model-item label="设为常用">
        <a-switch v-model="is_default" @change="changeDefault" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { saveInvoice } from "@/api/invoice";
// import addressBox from "@/components/address";
import { debounce } from "@/utils/throttle.js";
import axios from "axios";
var CryptoJS = require("crypto-js");

const clickOutside = {
  bind(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

export default {
  name: "addInvioce",
  props: {
    id: {
      type: [String, Number],
      default: 0,
    },
  },
  directives: {
    "click-outside": clickOutside, // 注册自定义指令
  },
  // components: { addressBox },
  data() {
    const validatePass2 = (rule, value, callback) => {
      // if (value === '') {
      //     callback(new Error('Please input the password again'));
      // } else if (value !== this.ruleForm.pass) {
      //     callback(new Error("Two inputs don't match!"));
      // } else {
      //     callback();
      // }
      if (!this.form1[rule.field]) {
        callback(new Error("请选择企业地址"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      form1: {
        headerType: "2",
        is_default: 1,
        firmName: "",
        firmDutyNumber: "",
        firmBank: "",
        firmBankSn: "",
        addressDetail: "",
        firmPhone: "",
        firmEmail: "",
        invoiceName: "",
        invoicePhone: "",
        invoiceEmail: "",
      },
      rules: {
        firmName: [
          { required: true, message: "请填写公司名称", trigger: "blur" },
        ],
        firmPhone: [
          { required: true, message: "请输入企业电话", trigger: "blur" },
        ],
        firmEmail: [
          { required: true, message: "请输入收票邮箱", trigger: "blur" },
          {
            required: true,
            pattern:
              "^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$",
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
        firmBank: [
          { required: true, message: "请输入开户银行", trigger: "blur" },
          {
            required: true,
            pattern: "^[\u4E00-\u9FA5A-Za-z0-9-、（） /]+$",
            message: "只允许输入 、 - （ ） / 特殊符号",
            trigger: "blur",
          },
        ],
        firmBankSn: [
          { required: true, message: "请输入开户行账号", trigger: "blur" },
        ],
        addressDetail: [
          { required: true, message: "请输入企业的详细地址", trigger: "blur" },
          {
            required: true,
            pattern: "^[\u4E00-\u9FA5A-Za-z0-9-、（） /]+$",
            message: "只允许输入 、 - （ ） / 特殊符号",
            trigger: "blur",
          },
        ],
        cityId: [
          { required: true, validator: validatePass2, trigger: "change" },
        ],
        firmDutyNumber: [
          { required: true, message: "请填写企业税号", trigger: "blur" },
        ],
        invoiceName: [
          { required: true, message: "请填写姓名", trigger: "blur" },
        ],
        invoicePhone: [
          { required: true, message: "请填写电话", trigger: "blur" },
        ],
        invoiceEmail: [
          { required: true, message: "请填写邮箱", trigger: "blur" },
          {
            required: true,
            pattern:
              "^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$",
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
      },
      confirmLoading: false,
      is_default: true,
      addressForm: {
        province_id: undefined,
        city_id: undefined,
        district_id: undefined,
      },
      isEdit: false,
      placeCompanyVisible: false,
      placeCompanyArray: [],
      invoiceApiSecretID: "AKID8vybpm4k1kbuooybmke1j32j6gt138gvkmiA",
      invoiceApiSecretKey: "d2k14lqhw73kexzxqodA8baw5lfivt1uu3cqf8fm",
    };
  },
  methods: {
    changeAddress(parmas) {
      this.$nextTick(() => {
        this.$refs.address.changeAddress(parmas);
      });
    },
    clearForm(e) {
      this.$refs.ruleForm.resetFields();
      this.form1.headerType = e.target.value;
      // this.form1 = {
      //   headerType: e.target.value,
      // };
      this.addressForm = {
        province_id: undefined,
        city_id: undefined,
        district_id: undefined,
      };
    },
    // waitCity() {
    // this.$emit("waitCity");
    // let params = {
    //     province_id: Number(this.detail.province_id),
    //     city_id: Number(this.detail.city_id),
    //     district_id: Number(this.detail.district_id)
    // }
    // this.$refs.addInvioce.addressForm = params
    // this.$refs.addInvioce.changeAddress(params)
    // },
    changeDefault(e) {
      this.form1.isDefault = e ? 1 : 0;
    },
    // selectAddress(ids) {
    //   this.form1 = {
    //     ...this.form1,
    //     ...{
    //       provinceId: ids.province_id,
    //       cityId: ids.city_id,
    //       districtId: ids.district_id,
    //     },
    //   };
    // },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          for (let key in this.form1) {
            if (typeof this.form1[key] === "string") {
              this.form1[key] = this.form1[key].replace(/[\s?]/g, "");
            }
          }
          saveInvoice(this.id, this.form1)
            .then((res) => {
              if (res.data.status != 200) {
                return this.$message.error(res.data.msg);
              }
              this.visible = false;
              this.$refs.ruleForm.resetFields();
              // this.form1 = { headerType: "2" };
              this.$message.success("保存成功");
              this.$emit("subSuccess");
              this.placeCompanyArray = [];
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel5() {
      this.visible = false;
      this.$refs.ruleForm.resetFields();
      // this.form1 = { headerType: "2" };
    },
    onChangeFirmName: debounce(function () {
      if (!this.form1.firmName) {
        this.placeCompanyArray = [];
        return;
      }
      const datetime = new Date().toGMTString();
      const source = "market";
      const signStr = "x-date: " + datetime + "\n" + "x-source: " + source;
      const sign = CryptoJS.enc.Base64.stringify(
        CryptoJS.HmacSHA1(signStr, this.invoiceApiSecretKey)
      );
      const auth = `hmac id="${this.invoiceApiSecretID}", algorithm="hmac-sha1", headers="x-date x-source", signature="${sign}"`;
      const params = new URLSearchParams();
      params.append("name", this.form1.firmName);
      const service = axios.create({
        baseURL: "https://service-rf059r1j-1258478321.sh.apigw.tencentcs.com",
        timeout: 20000,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Source": source,
          "X-Date": datetime,
          Authorization: auth,
        },
      });
      service({
        url: "/release/getInvocieInfo",
        method: "post",
        data: params,
      }).then((res) => {
        this.placeCompanyArray = res.data.data || [];
      });
    }, 1000),
    onSelectCompany(item) {
      this.form1.firmName = item.unitName;
      this.form1.firmDutyNumber = item.unitTaxNo;
      this.form1.firmBank = item.bankName;
      this.form1.firmBankSn = item.bankNo;
      this.form1.addressDetail = item.unitAddress;
      this.form1.firmPhone = item.unitPhone;
      this.placeCompanyVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
/* 火狐 */
input {
  -moz-appearance: textfield;
}

.pop-view {
  width: 100%;
  height: 200px;
  position: absolute;
  top: 100%;
  background-color: white;
  z-index: 100;
  overflow: scroll;
  box-shadow: 0 0 10px 1px #bab8b867;
}
</style>
